import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>:(</h1>
    <p>
      Nothing to see here. Head back over to my{' '}
      <a href="www.mikefrancis.co.nz">home page</a>.
    </p>
  </Layout>
)

export default NotFoundPage
